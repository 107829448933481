<template>
	<div class="">
		<div class="user-info">
			<div class="user-info-wrap">
				<div class="company-userlogo">
					<div class="company-name">
						<div class="company-logo"><img style="width: 245px;" src="https://pwa.ctpestsolutions.com/assets/images/CTPestSolutions-footer-logo.png" /></div>
					</div>
					<div class="user-logo">

						<button class="btn btn-info" type="button" @click="logout()" v-if="isLogged">
							Logout
							</button>
						<!-- <div class="logo-letter"><span>{{userLetter}}</span></div> -->
					</div>
				</div>
				<div class="user-name">
					<h3 style="font-size: 1.5rem;">Hello, <span class="user-name-span">{{username}}</span></h3>
				</div>
				<div class="date-col">
					<div id="reportrange" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 290px;">
						<i class="fa fa-calendar"></i>&nbsp;
						<span>Filter by date</span> <i class="fa fa-caret-down"></i>
					</div>
				</div>
			</div>
		</div>
		
	<div class="dashboard" id="style-1">
		<div class="dashboard-wrap">
			<!-- ####### Completed Work ####### -->
			<div class="completed-workssss">
				<div class="title">
				

				<div class="modal fade customer-modal" id="exampleModal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel4" aria-hidden="true">
					<div class="modal-dialog modal-dialog-slideout modal-md" role="document">
						<div class="modal-content">
						<div class="modal-header">
							<h5 v-if="panelType == 'unserviced'" class="modal-title" id="exampleModalLabel">Unserviced Customers</h5>
							<h5 v-else class="modal-title" id="exampleModalLabel">Cancelled Customers</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
							</button>
						</div>
						<div class="modal-body">							

						<ul class="c-list">
							<li v-for="customer in getPanelContent" :key="customer.customer_id">
								<div class="card-add">
									<div class="customer-name">{{ customer.customer_name }} </div>									
									<div v-if="customer.service_name" class="customer-loc"> <strong>{{ customer.starts_at|formatDate }}</strong> | <strong>{{ customer.service_name }}</strong> </div>
									<div v-if="customer.service_prod_value" class="customer-loc" style="font-weight: 700;" > <span style="color: #21c02f !important;"> $ </span> {{ customer.service_prod_value }} </div>									
									<div class="customer-loc"><i class="fa fa-angle-right"></i> {{ customer.customer_location }} </div>
									
								</div>
							</li>
						</ul>


						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-info" data-dismiss="modal">Close</button>
						</div>
						</div>
					</div>
				</div>
					<h6>Total Service Appointments - Completed Work Orders + Unservices + Cancelled</h6>
				</div>
				<div class="graph-img">
					<vue-ellipse-progress
						:progress="work_completed_persent"
						color="#000"
						:thickness="12"
						fontSize="3rem"
					>					
						<span slot="legend-value">%</span>
						<p slot="legend-caption">completed</p>
					</vue-ellipse-progress>
				</div>
				<div class="graph-details">
					<div class="row">
						<div class="col-5 col">
							<div class="numbers-col">
								<div><span :key="work_completed" class="numbers">{{work_completed}}</span></div>
								<div> <span class="section-details">Completed Appointments</span> </div>
							</div>
						</div>
						<div class="col-7 col">
							<div class="dollers-col">
								<div><span class="numbers usd">{{work_completed_total}}</span></div>
								<div> <span class="section-details">Value (USD)</span> </div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-5 col">
							<div class="numbers-col">
								<div><span class="numbers">{{number_of_work_order}}</span></div>
								<div><span class="section-details">Total Appointments</span></div>
							</div>
						</div>
						<div class="col-7 col">
							<div class="dollers-col">
								<div><span class="numbers">{{work_order_total}}</span></div>
								<div><span class="section-details">Total Value (USD)</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>			
			<!-- ####### Completed Work End ####### -->
			
			<!-- ####### Unserviced ####### -->
			<div class="unserviced">

				<div class="title">
					<h6>unserviced</h6>
				</div>

				<div class="graph-details">
					<div class="row">
						<div class="col-5 col">
							<div :key="graphKey" class="numbers-col">
								<div><span class="numbers">{{number_of_unserviced}}</span></div>
								<div><span class="section-details">Appointments</span></div>
								<button type="button" @click="panelType='unserviced'" class="btn view_slideout_btn" data-toggle="modal" data-target="#exampleModal4"><i class="fa fa-eye"></i> View Customers</button>
							</div>
						</div>
						<div class="col-7 col">
							<div class="dollers-col">
								<div><span class="numbers usd">{{unserviced_work_order_value}}</span></div>
								<div><span class="section-details">Value (USD)</span></div>
							</div>
						</div>
					</div>
				</div>
				<div class="graph-img">
					<progress-bar size="25" bar-color="#000" v-bind:val="unserviced_percent"></progress-bar>
					<p class="canceled-text">{{unserviced_percent}}% unserviced  (USD value)</p>
				</div>
			</div>
			<!-- ####### Unserviced End ####### -->
			
			<!-- ####### Cancellation ####### -->
			<div class="cancellation">
				<div class="title">
					<h6>cancelled</h6>
				</div>
				<div class="graph-details">
					<div class="row">
						<div class="col-5 col">
							<div class="numbers-col">
								<div><span class="numbers">{{cancled_customers}}</span></div>
								<div><span class="section-details">Appointments</span></div>
								<button type="button" @click="panelType='cancel'" class="btn view_slideout_btn" data-toggle="modal" data-target="#exampleModal4"><i class="fa fa-eye"></i> View Customers</button>
							</div>
						</div>
						<div class="col-7 col">
							<div class="dollers-col">
								<div><span class="numbers usd">{{cancled_value}}</span></div>
								<div><span class="section-details">Value (USD)</span></div>
							</div>
						</div>
					</div>
				</div>
				<div class="graph-img">
					<progress-bar size="25" bar-color="#000" v-bind:val="cancled_persent"></progress-bar>
					<p class="canceled-text">{{cancled_persent}}% cancelled (USD value)</p>
				</div>
			</div>
			<!-- ####### Cancellation End ####### -->
			
			<!-- ####### Your Salse ####### -->
			<div class="your-sales">
				<div class="title">
					<h6>Overall Appointments</h6>					
				</div>
				<div class="graph-img">
					<pie-chart :key="pieKey" :data="chartData" :options="chartOptions" style="width: 300px; height: 300px; margin: 0 auto;"></pie-chart>
				</div>
				<div class="graph-details">
					<div class="row">
						<div class="col-3 col">
							<div class="numbers-col">
								<div><span class="numbers">{{sales_pending}}</span></div>
								<div><span class="section-details">Total</span></div>
							</div>
						</div>
						<div class="col-3 col">
							<div class="numbers-col">
								<div><span class="numbers">{{sales_accepted}}</span></div>
								<div><span class="section-details">Completed</span></div>
							</div>
						</div>
						<div class="col-3 col">
							<div class="numbers-col">
								<div><span class="numbers">{{sales_declined}}</span></div>
								<div><span class="section-details">Unserviced</span></div>
							</div>
						</div>
						<div class="col-3 col">
							<div class="numbers-col">
								<div><span class="numbers">{{sales_expired}}</span></div>
								<div><span class="section-details">Cancelled</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- ####### Your Salse End ####### -->
			
		</div>
	</div>
	</div>
</template>
<style>
.vue-simple-progress-bar {
    border-radius: 15px;
}
</style>
<script>
import ProgressBar from 'vue-simple-progress'
import PieChart from "../PieChart.js";
// import Panel1 from './Panel.vue';
import jQuery from 'jquery';
import moment from 'moment'
require("daterangepicker");
import { mapGetters } from 'vuex'


export default {
  name: 'Dashboard',
  props: {
    msg: String
  },
  components: {
    ProgressBar,
	PieChart
  },
  data() {
	return {
		texte: '',
		all_data: [],
		panelType: '',
		username: null,
		userLetter: null,
		work_completed_persent: 0,
		work_completed: 0,
		work_completed_total: 0,
		number_of_work_order: 0,
		work_order_total: 0,
		number_of_unserviced: 0,
		unserviced_work_order_value: 0,
		unserviced_percent: 5,
		cancled_customers: 0,
		cancled_value: 0,
		cancled_persent: 5,
		sales_pending: 0,
		sales_accepted: 0,
		sales_declined: 0,
		sales_expired: 0,
		unserviced_customers: [],
		cancelled_customers: [],
		pieKey: 0,
		pie_data: [],
		graphKey:0,
		chartData: {
			labels: [ " Completed", " Unserviced", " Cancelled"],
			datasets: [
				{
					label: " appointments",
					backgroundColor: ["#40826d", "#FF6347", "#D8D8D8"],
					data: []
				}
			]
		},
		chartOptions: {
			responsive: true,
			legend: {
				display: false,
				position: 'center',
			},
		},
	}
  },
  computed: {
    ...mapGetters([
      'isLogged'
    ]),
    getPanelContent(){
		if(this.panelType == 'unserviced'){
			return this.unserviced_customers;
		} else if(this.panelType == 'cancel'){
			return this.cancelled_customers;
		} else{
			return [];
		}
		
	}
  },
  methods: {
	showPanel() {
		this.panelResult = this.$showPanel({
		component: 'Panel1',
		props: {
			age: 22
		}
		});
	},
	hidePanel() {
		this.panelResult.hide();
	},
    logout () {
      this.$store.dispatch('logout')
    }
  },
  watch: {
	pie_data (newData){
		
		const data = this.chartData
		data.datasets[0].data = newData
		this.chartData = {...data}
		this.pieKey++;
		
	}
  },
  mounted () {
	var _self = this;
	var start = moment().subtract(29, 'days');
    var end = moment();
	console.log([this.sales_pending, this.sales_accepted, this.sales_declined, this.sales_expired])
	
	jQuery('#reportrange').daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {           
           'Yesterday': [moment().subtract(2, 'days'), moment().subtract(1, 'days')],
           'Last 7 Days': [moment().subtract(6, 'days'), moment()],
           'Last 30 Days': [moment().subtract(29, 'days'), moment()],
           'This Month': [moment().startOf('month'), moment().endOf('month')],
           'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    }, function(start, end, label) {
		const currentUser = localStorage.getItem('user')
		const Obj = JSON.parse(currentUser);
		console.log(label);
		if(label == "Custom Range") {
			jQuery('#reportrange span').html(start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD'));
		} else {
			if(label) {
				jQuery('#reportrange span').html(label);
			} else {
				jQuery('#reportrange span').html('Filter by date');
			}
		}
		
		//console.log(start.format('YYYY-MM-DD'));

		//window.location = "http://localhost:8080/?t="+label.toLowerCase();

		_self.$store
			.dispatch('retWorkOrder', {
				service_route_id: Obj.user.service_route_id,
				s_date: start.format('YYYY-MM-DD'),
				e_date: end.format('YYYY-MM-DD'),
				d_label: label
			})
			.then((data) => {

				_self.all_data = data;

				_self.work_completed_persent = data.completion_rate
				_self.work_completed = data.completed.data[0].count
				_self.work_completed_total = data.completed.total

				_self.unserviced_customers = data.unserviced_customers;
				_self.cancelled_customers = data.cancelled_customers;

				_self.number_of_work_order = data.total_orders
				_self.work_order_total = '$'+data.total_usd

				_self.number_of_unserviced = (data.unserviced.data[0].count) ? data.unserviced.data[0].count : 0
				_self.unserviced_work_order_value = (data.unserviced.total) ? data.unserviced.total : 0
				
				var unservice_amount = data.unserviced.total.replace(/\$/g, '');

				var unservice_percent = ( unservice_amount / data.total_usd * 100 );

				unservice_percent = (unservice_percent.toFixed(2))

				_self.unserviced_percent = unservice_percent ? unservice_percent : 0

				_self.cancled_customers = (data.cancelled.data[0].count) ? data.cancelled.data[0].count : 0
				_self.cancled_value = (data.cancelled.total) ? data.cancelled.total : 0
				
				
				var cancel_amount = data.cancelled.total.replace(/\$/g, '');
				var cancel_percent = ( cancel_amount / data.total_usd * 100);
				cancel_percent = (cancel_percent.toFixed(2))

				_self.cancled_persent = cancel_percent ? cancel_percent : 0

				_self.sales_pending = (data.total_orders) ? data.total_orders : 0
				_self.sales_accepted = (data.completed.data[0].count) ? data.completed.data[0].count : 0
				_self.sales_declined = (data.unserviced.data[0].count) ? data.unserviced.data[0].count : 0
				_self.sales_expired = (data.cancelled.data[0].count) ? data.cancelled.data[0].count : 0


				_self.pie_data = [ _self.sales_accepted, _self.sales_declined, _self.sales_expired ]

				_self.graphKey++;

			})
			.catch(err => {
				console.log(err)
			})
		
	});
  
	const currentUser = localStorage.getItem('user')
	const Obj = JSON.parse(currentUser);
	this.username = Obj.user.name;
	this.userLetter = Obj.user.name.split(" ").map((n)=>n[0]).join("");
	
	this.$store
			.dispatch('retWorkOrder', {
				service_route_id: Obj.user.service_route_id,
				account_id: Obj.user.account_id,
				s_date: null,
				e_date: null,
				d_label: this.$route.query.t
			})
			.then((data) => {

				this.all_data = data

				this.unserviced_customers = data.unserviced_customers;
				this.cancelled_customers = data.cancelled_customers;
                
				this.work_completed_persent = data.completion_rate
				this.work_completed = data.completed.data[0].count
				this.work_completed_total = data.completed.total

				this.number_of_work_order = data.total_orders
				this.work_order_total = '$'+data.total_usd

				this.number_of_unserviced = (data.unserviced.data[0].count) ? data.unserviced.data[0].count : 0
				this.unserviced_work_order_value = (data.unserviced.total) ? data.unserviced.total : 0
				
				var unservice_amount = data.unserviced.total.replace(/\$/g, '');

				var unservice_percent = ( unservice_amount / data.total_usd * 100 );
				
				unservice_percent = (unservice_percent.toFixed(2))

				_self.unserviced_percent = unservice_percent ? unservice_percent : 0

				this.cancled_customers = (data.cancelled.data[0].count) ? data.cancelled.data[0].count : 0
				this.cancled_value = (data.cancelled.total) ? data.cancelled.total : 0


				var cancel_amount = data.cancelled.total.replace(/\$/g, '');

				var cancel_percent = ( cancel_amount / data.total_usd * 100);


				cancel_percent = (cancel_percent.toFixed(2))

				this.cancled_persent = cancel_percent ? cancel_percent : 0

				this.sales_pending = (data.total_orders) ? data.total_orders : 0
				this.sales_accepted = (data.completed.data[0].count) ? data.completed.data[0].count : 0
				this.sales_declined = (data.unserviced.data[0].count) ? data.unserviced.data[0].count : 0
				this.sales_expired = (data.cancelled.data[0].count) ? data.cancelled.data[0].count : 0


				this.pie_data = [ this.sales_accepted, this.sales_declined, this.sales_expired ]
				this.graphKey++;
			})
			.catch(err => {
				console.log(err)
			})
	
	this.$store
			.dispatch('getServices')
			.then((data) => {
				console.log(data)
			})
			.catch(err => {
				console.log(err)
			})
	
	this.$store
			.dispatch('getCustomers')
			.then((data) => {
				console.log(data)
			})
			.catch(err => {
				console.log(err)
			})
		
  }
  
}
</script>